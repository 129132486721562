<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
        <div class="relative w-full px-6">
            <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center">
                <img class="w-3 h-3" :src="close" alt="close-icon" @click="handleCloseModal">
            </div>
            <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
                <div class="flex flex-col gap-8">
                    <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Bienvenido</p>
                    <form class="flex flex-col gap-8 px-6" @submit.prevent="login">
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1 w-full">
                            <label class="text-xl" for="username">Email</label>
                            <div class="flex items-center gap-2">
                                <img :src=mail alt="" class="w-4 h-4">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold" type="text" id="username" placeholder="ejemplo@correo.com" v-model="credentials.username">
                            </div>
                            <img :src=line alt="" class="w-full">
                        </div>
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="password">Contraseña</label>
                            <div class="flex items-center gap-2">
                                <img :src=lock alt="" class="w-4 h-4">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold" type="password" id="password" placeholder="••••••••" v-model="credentials.password">
                            </div>
                            <img :src=line alt="" class="w-full">
                            <p v-if="loginError" class="text-red-500">{{ loginError }}</p>
                        </div>                        
                        <button type="submit" class="bg-tp-yellow text-tp-black font-bold text-lg px-20 py-2 rounded-full mt-4">Entrar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import mail from '@/assets/images/mail.png';
    import lock from '@/assets/images/lock.png';
    import line from '@/assets/images/green-line.png';
    import close from '@/assets/images/black-close-icon.png';
    import {userLogin} from '../../service/djService';
    import { getSessionsInfo} from '../../service/djService';


    export default {
        name: 'DJLoginModal',
        setup(props, { emit }) {
            const router = useRouter();

            const credentials = ref({
                username: '',
                password: '',
            });

            const sessionsInfo = ref(null);

            const loginError = ref(null);

            
            const login = async () => {
                const response= await userLogin(credentials.value.username, credentials.value.password);
                if (response.result === true){
                    sessionsInfo.value = await getSessionsInfo();
                    if (sessionsInfo.value.current_session !== null){
                        if (sessionsInfo.value.current_session.loungeData.hexID){
                            const loungeId = sessionsInfo.value.current_session.loungeData.hexID
                            router.push(`/dj/active-session/${loungeId}`);
                        }
                        else{
                            router.push('/dj/private-area');
                        }     
                    }
                    else{
                        router.push('/dj/private-area');
                    }                
                }
                else{
                    loginError.value = response.error;
                }
            };

            const handleCloseModal = () => {
                emit('onCloseModal');
            }

            return {
                login,
                credentials,
                mail,
                lock,
                line,
                close,
                handleCloseModal,
                loginError
            }
        }
    }
</script>

<style scoped>
input::placeholder {
    color: #f9f871 !important;
}

</style>