<template>
    <NavBar />
    <div class="flex flex-col gap-4 px-6 py-2 relative">
        <Timer class="absolute -top-1 right-8" v-if = "isLoaded && Math.floor(this.timeToEndSession / 60) <= 30" :initialTime="timeToEndSession" />
        <div class="flex justify-between items-center">
            <p class="font-extrabold text-2xl">Votaciones</p>
            <!-- <img @click="handlePrivateAreaRedirection" :src="settings" alt="" class="w-5 h-5 cursor-pointer"> -->
        </div>
        <div class="flex flex-col gap-6">
            <div v-if="votingFinished || rankingFinished" class="flex flex-col gap-3">
                <p class="font-semibold">¡Debería estar sonando esta canción!</p>
                <DJRankingSongCard 
                    :rankingSong="songToPlay" 
                    :index="1" 
                    :isGeneralVoting="true"
                    :isShouldPlay="true"
                />
            </div>
            <DJGeneralVotingSection v-if="isLoaded" :publicData = "loungeDetails.public" :loungeId = "loungeId" :polls="polls" :rankingIsInCountdown="rankingIsInCountdown" />
            <DJRankingSection v-if="isLoaded" :rankingData ="loungeDetails.public" :loungeId = "loungeId" />
            <DJShouldPlaySongModal v-if="finishedModal && songToPlay" :songToShow="songToPlay" @onCloseModal="handleCloseModal"/>
            <button
                v-if="!rankingIsInCountdown && !votingIsInCountdown" 
                class="bg-tp-yellow p-3 rounded-xl mb-4 mx-auto mt-10" 
                @click="openCloseSessionModal">
                <p class="text-tp-black font-bold">Cerrar sesión</p>
            </button>
            <DJCloseSessionModal v-if="closeSessionModal"  @onCloseSession="handleCloseSession" @onCloseModal="handleCloseCloseSessionModal"/>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/Common/NavBar.vue';
import DJGeneralVotingSection from '@/components/DJ/DJGeneralVotingSection.vue';
import DJRankingSection from '@/components/DJ/DJRankingSection.vue';
import settings from '@/assets/images/settings.png';
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import createLoungeService from '@/service/loungeService';
import DJShouldPlaySongModal from '../../components/DJ/DJShouldPlaySongModal.vue';
import DJRankingSongCard from '@/components/DJ/DJRankingSongCard.vue';
import * as djService from '@/service/djService'
import Timer from '../../components/Common/Timer.vue';
import { closeSession } from '../../service/djService';
import DJCloseSessionModal from '../../components/DJ/DJCloseSessionModal.vue';

export default {
    name: 'DJActiveSession',
    components: {
        NavBar,
        DJGeneralVotingSection,
        DJRankingSection,
        DJShouldPlaySongModal,
        DJRankingSongCard,
        Timer,
        DJCloseSessionModal
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const loungeId = route.params.id;
        const loungeDetails = ref(null);
        const isLoaded = ref(false);
        const state = ref(null);
        const { fetchLoungeDetails } = createLoungeService();
        const rankingFinished = ref(false);
        const votingFinished = ref(false);
        const finishedModal = ref(false);
        const songToPlay = ref(null);
        const pollingInterval = ref(null);
        const polls = ref(null)
        const timeToEndSession = ref(null);
        const closeSessionModal = ref (false);
        const rankingIsInCountdown = ref(false);
        const votingIsInCountdown = ref(false);


        const fetchDetails = async () => {
            if (!loungeId){
                isLoaded.value=true;
                return;
            }
            const details = await fetchLoungeDetails(loungeId);
            if (details && details.public) {
                loungeDetails.value = details;
                state.value = details.public.status;
                timeToEndSession.value = details.public.timeToEndSession;
            }
            checkFinished();
            isLoaded.value = true;
        };

        const handlePrivateAreaRedirection = () => {
            router.push('/dj/private-area')
        }

        const checkFinished = async () => {
            if (loungeDetails.value.public && loungeDetails.value.public.status){
                if (loungeDetails.value.public.status === "FINISHED_GENERAL_VOTE"){
                    if (votingFinished.value !== true){
                        await getSongToPlay("voting");
                        finishedModal.value =true;
                        votingIsInCountdown.value = false;
                    }
                    votingFinished.value = true;
                }
                else if (loungeDetails.value.public.status === "FINISHED_RANKING"){
                    if (rankingFinished.value !== true){
                        await getSongToPlay("ranking");
                        finishedModal.value =true;
                    }
                    rankingFinished.value = true;
                }
                else if (loungeDetails.value.public.status === "NOT_ACTIVE"){
                    router.push("/dj/private-area")
                }
                else if (loungeDetails.value.public.status === "GENERAL_VOTE"){
                    votingIsInCountdown.value = true;
                }
                else{
                    rankingIsInCountdown.value = loungeDetails.value.public.ranking.timeLeft && loungeDetails.value.public.ranking.timeLeft > 0;
                    votingFinished.value = false;
                    rankingFinished.value = false;
                }
            }
        }

        const getSongToPlay = async (type) => {
            let songsData;
            if (type === "voting") {
                songsData = Object.values(loungeDetails.value.public.votingData.songs)
                songToPlay.value =  songsData[0];
            } else {
                songToPlay.value = loungeDetails.value.public.lastRankingWinner;
            }
        };

       

        onMounted(async () => {
            await fetchDetails();
            pollingInterval.value = setInterval(fetchDetails, 5000);
            polls.value = await djService.getPrecreateVote(loungeId)
        });

        onBeforeUnmount(() => {
            if (pollingInterval.value) {
                clearInterval(pollingInterval.value);
                pollingInterval.value = null;
            }
        });
            

        const handleCloseModal = () => {
            finishedModal.value = false;
        };

        const openCloseSessionModal = () => {
            closeSessionModal.value = true;
        };

        const handleCloseCloseSessionModal = () => {
            closeSessionModal.value = false;
        };

        const handleCloseSession = () => {
            closeSession(loungeId);
            closeSessionModal.value = false;
        };
        
        return {
            settings,
            handlePrivateAreaRedirection,
            state,
            loungeDetails,
            isLoaded,
            loungeId,
            finishedModal,
            rankingFinished,
            votingFinished,
            handleCloseModal,
            songToPlay,
            djService,
            polls,
            timeToEndSession,
            closeSessionModal,
            openCloseSessionModal,
            handleCloseCloseSessionModal,
            handleCloseSession,
            rankingIsInCountdown,
            votingIsInCountdown
        }
    }
}

</script>