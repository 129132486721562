<template>
  <div class="flex flex-col items-start gap-2">
    <div class="flex gap-2 items-center">
      <p class="font-semibold text-xl">4-Picks</p>
      <img v-if="isActive && !rankingIsInCountdown" :src="live" alt="" class="w-5 h-5 animate-bounce">
    </div> 
    <div v-if="isActive && !rankingIsInCountdown" class="flex justify-between items-center bg-tp-black border-2 border-tp-yellow p-4 rounded-xl w-full">
      <div class="flex flex-col gap-1 bg-tp-black">
        <p v-for="(votingSong, index) in generalSongs" :key="index" class="font-medium w-40 xs:w-56 truncate">
          <span class="font-semibold">{{ index+1 }}.</span> {{ votingSong.songName }} - {{ votingSong.artist }}
        </p>
      </div>
      <Timer v-if ="timeLeft " :initialTime="timeLeft" />
    </div>
    <DJControlPanel v-if="(polls || isActive) && !rankingIsInCountdown" class="cursor-pointer" @click="handleControlPanelRedirection" />
    <div v-if="!polls && !rankingIsInCountdown && !isActive" class="flex flex-col justify-center gap-2">
      <p class="font-medium">No hay ningún 4-Picks creado</p>
      <button class="bg-tp-yellow text-tp-black font-bold py-3 px-6 rounded-xl w-max cursor-pointer" @click="handleCreateGeneralVoting">Crear 4-Picks</button>
    </div>
  </div>
  <p v-if="rankingIsInCountdown" class="text-tp-yellow font-semibold">
      No se puede acceder a 4-Picks mientras haya un contador activo en el ranking
    </p>
</template>
  
<script>
  import live from '@/assets/images/live.png';
  import DJControlPanel from './DJControlPanel.vue';
  import { useRouter } from 'vue-router';
  import { onMounted, watch, ref } from 'vue';
  import Timer from '@/components/Common/Timer.vue';

  export default {
    name: 'DJGeneralVotingSection',
    props: {
      publicData: Object,
      loungeId: String,
      polls: Object,
      rankingIsInCountdown: Boolean,
    },
    components: {
      DJControlPanel,
      Timer
    },
    setup(props) {
      const router = useRouter();
      
      const generalSongs = ref(null);

      const timeLeft = ref (null);

      const isActive = ref (false);
      
      const handleControlPanelRedirection = () => {
        if (isActive.value === true){
          router.push(`/dj/active-session/${props.loungeId}/activeVoting`);
        }
        else{
          router.push(`/dj/active-session/${props.loungeId}/general-votings`)
        }     
      };

      const handleCreateGeneralVoting = () => {
        router.push(`/dj/active-session/${props.loungeId}/create-general-voting`)
      };

      const refreshVotes = (songsData) => {
        generalSongs.value = songsData.map(song => {
          return {
            ...song,
            votes: song.votos
          };
        });
      };

      onMounted(async () => { 
        if (props.publicData && props.publicData.status) {
          if (props.publicData.status === "GENERAL_VOTE"){
            isActive.value = true;
            const songsData = Object.values(props.publicData.votingData.songs);
            const songIds = songsData.map(song => song.songID);
            if (songIds.length > 0) {
              refreshVotes(songsData);
            }
            timeLeft.value=props.publicData.votingData.timeLeft;
            }
          else{
            isActive.value = false;
          }
        }
      });

      watch(() => props.publicData, (newPublicData) => {
        const handleData = async () => {
          if (newPublicData) {
            if (props.publicData.status === "GENERAL_VOTE") {
              isActive.value = true;
              const songsData = Object.values(newPublicData.votingData.songs);
              const songIds = songsData.map(song => song.songID);

              if (songIds.length > 0) {
                refreshVotes(songsData);
              }
              timeLeft.value = newPublicData.votingData.timeLeft;
            } else {
              isActive.value = false;
            }
          }
        };

       handleData();   
      }, { deep: true });

      return {
        live,
        handleControlPanelRedirection,
        handleCreateGeneralVoting,
        generalSongs,
        isActive,
        timeLeft
      };
    }
  };
  
</script>