<template>
    <div class="modal-overlay" @click.self="handleCloseModal"> 
      <div class="relative w-full px-6">
        <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center">
            <img class="w-3 h-3" :src="close" alt="close-icon" @click="emitCloseModal">
        </div>
        <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
            <div class="flex flex-col gap-8">
                <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Eliminar canción</p>
                <p  class="text-tp-yellow font-semibold px-6">No podrás revertirlo y no recibirás el dinero que haya generado la canción</p>
                <div class="flex gap-4 w-full px-6">
                <button 
                    class="bg-tp-yellow p-3 rounded-xl flex-1 whitespace-nowrap" 
                    @click="emitRejectSong">
                    <p class="text-tp-black font-bold text-sm">Eliminar</p>
                </button>
                <button 
                    class="bg-tp-yellow p-3 rounded-xl flex-1 whitespace-nowrap" 
                    @click="emitCloseModal">
                    <p class="text-tp-black font-bold text-sm">Cancelar</p>
                </button>
                </div>
            </div>
        </div>
      </div> 
    </div> 
  </template>
  

<script>

import close from '@/assets/images/black-close-icon.png';

export default {
    name: 'DJConfirmRejectSongModal',
    props: {
        songId: Number
    },
    setup(props, { emit }) {

        const emitRejectSong = () => {
            emit('onRejectSong', props.songId); 
        };

        const emitCloseModal = () => {
            emit ('onCloseModal');
        }

        const handleCloseModal = () => {
            emit('onCloseModal');
        }

        return {
            close,
            emitRejectSong,
            handleCloseModal,
            emitCloseModal
        }
    }
}
</script>
  