<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
      <div class="relative w-full px-6">  
        <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center">
            <img class="w-3 h-3" :src="close" alt="close-icon" @click="handleCloseModal">
        </div>     
        <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
          <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">{{ title }}</p>
          <div class="flex flex-col gap-4 px-6">
            <p class="text-tp-yellow text-base">{{ message }}</p>
          </div>
          <div class="flex gap-2 px-6 justify-center">
            <button class="bg-tp-yellow text-tp-black font-semibold py-2 px-4 rounded-full" @click="handleCloseModal">
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import close from '@/assets/images/black-close-icon.png';
  
  export default {
    name: 'GenericInfoModal',
    props: {
      title: {
        type: String,
        required: true
      },
      message: {
        type: String,
        required: true
      },
      buttonText: {
        type: String,
        required: true
      }
    },
    emits: ['onCloseModal'],
    setup(props, { emit }) {
      const handleCloseModal = () => {
        emit('onCloseModal');
      };
      return {
        close,
        handleCloseModal
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  </style>
  