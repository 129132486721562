<template>
  <div class="relative">
    <NavBar />
    <DJActionButton class="absolute xxs:top-24 top-20 left-8" :image="arrowBack" @click="handleBack"/>
    <div class="flex flex-col items-start gap-3 px-8 pt-4 pb-10 w-full">
      <p class="flex justify-center font-bold text-2xl text-center w-full">Ranking</p>      
      <p v-if="timerIsNull && !isRankingFinished" class="font-semibold">¡Lanza la cuenta atrás para reproducir la canción que vaya primera!</p>
      <p v-if="!timerIsNull" class="font-semibold">Deberás reproducir la canción que vaya primera cuando se acabe el contador y te avise.</p>
      <p v-if="isRankingFinished" class="font-semibold">¡Debería estar sonando la canción que está redondeada en rojo!</p>
      <p v-if="noRanking" class="font-semibold">Ahora mismo no hay ningún ranking activo</p>
      <Timer v-if = "detailsLoaded && !timerIsNull" :initialTime="timeLeft" />
      <div v-if = "timerIsNull && !isRankingFinished"
      @click="startCountdown" 
       class="flex items-center bg-tp-yellow gap-1 py-2 px-4 rounded-full cursor-pointer">
        <p class="font-semibold text-tp-black">Lanzar</p>
        <img :src="clock" alt="" class="w-4 h-4">
      </div>
      <DJRankingSongCard v-if="isRankingFinished" 
        :rankingSong="songToPlay"
        :index="1" 
        :isGeneralVoting="true"  
        :isShouldPlay="true"/>
      <div class="flex flex-col gap-3 w-full">
        <DJRankingSongCard v-for="(rankingSong, index) in rankingSongs" 
        :key="index" 
        :rankingSong="rankingSong" 
        :index="index+1" 
        :isShouldPlay="false" 
        @deleteSong="handleOpenRejectSongModal(rankingSong.songId)"/>
      </div>
      <DJShouldPlaySongModal v-if = "rankingFinishedModal" :songToShow="songToPlay" @onCloseModal="handleCloseModal"/>
    </div>
    <DJConfirmRejectSongModal v-if="rejectSongModalVisible" :songId="songToReject" @onRejectSong="handleDeleteSong" @onCloseModal="handleCloseRejectModal"/>
    
  </div>


</template>

<script>
import NavBar from '@/components/Common/NavBar.vue';
import DJActionButton from '@/components/DJ/DJActionButton.vue';
import DJRankingSongCard from '@/components/DJ/DJRankingSongCard.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import clock from '@/assets/images/black-clock.png';
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, onBeforeUnmount} from 'vue';
import createLoungeService from '@/service/loungeService';
import { rejectSong } from '../../service/djService';
import Timer from '@/components/Common/Timer.vue';
import DJShouldPlaySongModal from '../../components/DJ/DJShouldPlaySongModal.vue';
import DJConfirmRejectSongModal from '../../components/DJ/DJConfirmRejectSongModal.vue';
import { rankingCountdown } from '../../service/djService';


export default {
  name: 'DJRanking',
  components: {
    NavBar,
    DJActionButton,
    DJRankingSongCard,
    Timer,
    DJShouldPlaySongModal,
    DJConfirmRejectSongModal
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loungeId = route.params.id;
    const loungeDetails = ref(null);
    const detailsLoaded = ref (false);
    const rankingSongs = ref(null);
    const timeLeft = ref (null);
    const timerIsNull = ref(null);
    const rankingFinishedModal = ref(false);
    const isRankingFinished = ref(false);
    const noRanking = ref (false);
    const pollingInterval = ref(null);
    const rejectSongModalVisible = ref(false);
    const songToReject = ref(null);
    const songToPlay = ref(null);

    const { fetchLoungeDetails } = createLoungeService();

    const handleBack = () => {
      router.go(-1);
    };

    const fetchDetails = async () => {
        if (!loungeId) return;
        const details = await fetchLoungeDetails(loungeId);
        if (details && details.public) {
          loungeDetails.value = details;
        }
        checkState();
        if (loungeDetails.value && loungeDetails.value.public && loungeDetails.value.public.ranking) {
            const songsData = Object.values(loungeDetails.value.public.ranking.songs); 
            updateRanking(songsData);
            if (loungeDetails.value.public.ranking.timeLeft === null){
              timerIsNull.value = true;
            }
            else{
              timerIsNull.value = false;
              if(isRankingFinished.value === true){
                timeLeft.value = 180 + loungeDetails.value.public.ranking.timeLeft;

              }
              else{
                timeLeft.value = loungeDetails.value.public.ranking.timeLeft;
              }
              
            }
            detailsLoaded.value = true;
        }
    };

    const updateRanking = async (songsData) => {
      rankingSongs.value = songsData.map(song => {
          return {
            ...song.songInfo,
            votes: song.votos,
            revenue: song.revenue
          };
        })
    };

    const checkState = async () => {
      if (loungeDetails.value.public && loungeDetails.value.public.status){
        if (loungeDetails.value.public.status === "RANKING" || loungeDetails.value.public.status === "FINISHED_GENERAL_VOTE"){
          isRankingFinished.value = false;
        }
        else if (loungeDetails.value.public.status === "FINISHED_RANKING"){
          if (isRankingFinished.value !== true){
            const songDetails = loungeDetails.value.public.lastRankingWinner;
            songToPlay.value =  songDetails;
            rankingFinishedModal.value =true;
          }
          isRankingFinished.value=true;
        }
        else{
          noRanking.value=true;
          router.push(`/dj/active-session/${loungeId}`)
        }
      }

    }

    const handleCloseModal = () => {
      rankingFinishedModal.value = false;
    }
    
    const startCountdown = ()=> {
      try{
        rankingCountdown(loungeId)
      }
      catch{
        console.log('error')
      }
    }

    onMounted(async () => {
        await fetchDetails(); 
        pollingInterval.value = setInterval(fetchDetails, 5000); 
    });

    onBeforeUnmount(() => {
            clearInterval(pollingInterval.value);
            pollingInterval.value = null; 
        });

    const handleDeleteSong = () => {
      rejectSong (songToReject.value, loungeId)
      rejectSongModalVisible.value = false;
    }

    const handleOpenRejectSongModal = (song) => {
      songToReject.value = song;
      rejectSongModalVisible.value = true;
    }

    const handleCloseRejectModal = () => {
      rejectSongModalVisible.value = false;
    }

    return {
      arrowBack,
      clock,
      handleBack,
      rankingSongs,
      handleDeleteSong,
      detailsLoaded,
      timerIsNull,
      timeLeft,
      rankingFinishedModal,
      handleCloseModal,
      isRankingFinished,
      noRanking,
      rejectSongModalVisible,
      songToReject,
      handleOpenRejectSongModal,
      handleCloseRejectModal,
      startCountdown,
      songToPlay
    }
  }
}
</script>